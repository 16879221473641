import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Header from "../Components/Header/Header";
import Aside from "../Components/Aside/Aside";
import { useNavigate } from "react-router-dom";
import { Api } from "../../../helpers/Api";

const Home = ({ user, ...props }) => {
  let navigate = useNavigate();
  useEffect(() => {
    if (user.role == 1) {
      navigate(`/brands`);
    } else {
      Api.call(`/users/${user.id}`).then((response) => {
        if (response.departments.length != 0) {
          navigate(`/templates`);
        } else {
          navigate(`/departments`);
        }
      });
    }
  }, []);
  return (
    <div className="wrapper">
      <Header />
      <Aside />
      <div className="dashboard"></div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatchUpdate: (user) => dispatch({ type: "UPDATE_USER", data: user }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
