import React, {useEffect, useRef, useState} from 'react';
import {Image, Label, Layer, Stage, Text} from 'react-konva';


const CertificateViewer = ({id, labels,images, data, backgroundImageUrl, qr}) => {

    const [backgroundImage, setBackgroundImage] = useState(null);
    const [qrImage, setQRImage] = useState(null);
    const [imageMaps, setImageMaps] = useState({});
    const [qrUrl, setQrUrl] = useState(null);
    const handImageLoad = (e) => {
        setBackgroundImage(e.target)
    }
    const handQRImageLoad = (e) => {
        setQRImage(e.target)
    }

    const stageRef = useRef();

    const onDownload = ()=>{
        const uri = stageRef.current.toDataURL();
        downloadURI(uri, 'certificate.png');
    }

    useEffect(()=>{
        if(id){
            setQrUrl('https://api.qrserver.com/v1/create-qr-code/?size=50x50&data=https://events.chitkara.edu.in/certificate/'+id);
        }
    },[id])

    function downloadURI(uri, name) {
        var link = document.createElement('a');
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    useEffect(() => {
        if (qrUrl) {
            const image = new window.Image();
            image.crossOrigin = 'Anonymous';

            image.src = qrUrl;
            image.addEventListener('load', handQRImageLoad);
            return () => {
                image.removeEventListener('load', handQRImageLoad)
            };
        }
    }, [qrUrl]);

    useEffect(() => {
        if (backgroundImageUrl) {
            const image = new window.Image();
            image.crossOrigin = 'Anonymous';

            image.src = backgroundImageUrl;
            image.addEventListener('load', handImageLoad);
            return () => {
                image.removeEventListener('load', handImageLoad)
            };
        }
    }, [backgroundImageUrl]);

    useEffect(() => {
        images.forEach(({url}, index) => {
            const image = new window.Image();
            image.src = url;
            image.addEventListener('load', (e) => {
                setImageMaps(i => ({...i, [index]: e.target}))
            });
        })

    }, [images]);

    return (
        <>
            <Stage width={800} height={700} ref={stageRef}>
                <Layer>
                    <Image image={backgroundImage} width={800} height={700}/>
                </Layer>
                {qr &&
                <Layer>
                    <Image image={qrImage} x={749} y={349}/>
                </Layer>
                }
                <Layer>
                    {images.map((star, index) => (
                        <Image
                            id={star.id}
                            key={star.key}
                            x={star.x}
                            y={star.y}
                            width={star.width}
                            height={star.height}
                            image={imageMaps[index]}
                            opacity={1}
                            draggable
                        />

                    ))}

                </Layer>
                <Layer>
                    {labels.map((star, index) => (

                        <Label

                            id={star.id}
                            key={star.key}
                            x={star.x}
                            y={star.y}
                            opacity={1}
                            rotation={star.rotation}
                        >
                            <Text text={data[star.id] || star.text} fontSize={star.fontSize} fontFamily={star.fontFamily || "Arial"} fill={star.color}/>
                        </Label>
                    ))}
                </Layer>
            </Stage>
            <div className='download-button' style={{cursor: 'pointer'}} onClick={onDownload}>Download</div>
        </>
    );
};
export default CertificateViewer;
