import React from 'react'
import {Button} from "react-bootstrap";


export default class SubmitButton extends React.Component {

    render() {
        if(this.props.loading){
            return <div>Please wait..</div>;
        }
        return <Button className='submit' variant="primary" type="submit">
            {this.props.label}
        </Button>
    }
}
