import * as React from 'react';
import {useEffect, useState} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import {useNavigate, useParams} from "react-router-dom";
import {Api} from "../../../helpers/Api";
import {withRouter} from "../../../hoc/withRouter.ts";
import {cloneDeep} from 'lodash';
import CertificateViewer from "./CertificateViewer";
import {v4 as uuidv4} from 'uuid';

const TEMPLATE_NEW = {
    id: null,
    name: "",
    data: {
        labels: [],
        backgroundImageUrl: null,
        images: [],
    }
}


const EditCertificate = ({dispatchLogin, location}) => {

    const [loading, setLoading] = useState(false);
    const [event, setEvent] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [template, setTemplate] = useState(null);   
    const [certificate, setCertificate] = useState({data: {}, template: {data: {}}});
    const [selectedEvent, setSelectedEvent] = useState();
    const [selectedTemplate, setSelectedTemplate] = useState();
    const [isDisabled, setIsDisabled] = useState(false);
    
    const navigate = useNavigate();
    const {id} = useParams();

    useEffect(() => {
        Api.getTemplates().then(response => {
            setEvent(response);   
            console.log(response)       
            if (response.length > 0) {
                setTemplates(t => (t || response[0]))
            }
        });
    }, []);
    useEffect(() => {
        Api.getCertificate(id).then(response => {
            setCertificate(response);
            setTemplate(response.templateData);
            setSelectedEvent(response.template.id);  
            setSelectedTemplate(response.templateData.TemplateName)
            setIsDisabled(true);
        })
    }, [id])

    useEffect(() => {
        if(event.length > 0 && selectedEvent != null)
        {
                let t = event.find(t => {
                return t.id == selectedEvent;
            });
            setTemplates(t);
        }
    }, [event]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const payload = cloneDeep(certificate);
        payload.templateData = template;
        payload.template = templates;
        payload.uuid = uuidv4()

        Api.updateCertificate(payload).then(response => {
            console.log(templates);
            setCertificate(response)
            navigate("/certificates/")
        }).catch(e => {
        }).finally(() => {
            setLoading(false);
        })
    };

    const handleChange = (e => {
        let t = cloneDeep(certificate);
        t[e.target.id] = e.target.value;
        setCertificate(t);
    });

    return (<div className='cerritificate-view-screen'>
        <div className='certificate-heading'>
            <Typography component="h1" variant="h5">
                Create Certificate
            </Typography>
        </div>
        <div className='certificate-add'>
            <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                <LockOutlinedIcon/>
            </Avatar>
            
            <div className='form' onSubmit={handleSubmit}>
                <Row>
                    <Col md={12}>
                        <Form.Group>
                            <Form.Label>Event Name</Form.Label>
                            <select value={selectedEvent} disabled={isDisabled} onChange={(e) => {
                                let t = event.find(t => {
                                    return t.id == e.target.value;
                                });
                                setTemplates(t);
                                setTemplate(t.data[0]);
                            }}>
                                <option>Please Select</option>
                                {event.map((t) => {
                                    return <option value={t.id}>{t.name}</option>
                                })}
                            </select>
                        </Form.Group>
                    </Col>
                    <div>
                    {templates.data &&                    
                    <Row>
                    <Col md={12}>
                        <Form.Group>
                            <Form.Label>Templates Name</Form.Label>
                            <select onChange={(e) =>{
                                let t = templates.data.find(t => {
                                    return t.TemplateName == e.target.value;
                                })
                                setTemplate(t);
                            }}>
                                {templates.data.map((t) => {
                                    return <option value={t.TemplateName}>{t.TemplateName}</option>
                                })}
                            </select>
                        </Form.Group>
                    </Col>
                    </Row>
                    }
                        {/* <Col md={4}>
                        <Form.Group>
                            <Form.Label>Student Name</Form.Label>
                            <input className='form-control' id={`student-name`}
                                value={certificate.data.name} onChange={e => {
                                let c = cloneDeep(certificate);
                                c.data.name = e.target.value;
                                setCertificate(c);
                            }}/>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group>
                            <Form.Label>Student Email</Form.Label>
                            <input className='form-control' id={`student-email`}
                                value={certificate.data.email} onChange={e => {
                                let c = cloneDeep(certificate);
                                c.data.email = e.target.value;
                                setCertificate(c);
                            }}/>
                        </Form.Group>
                    </Col> */}
                    {template &&
                    <Row>
                    {template.label.map(label => {
                        return <Col md={4}>
                            <Form.Group>
                                <Form.Label>{label.text}</Form.Label>
                                <input className='form-control' id={`label-${label.id}`}
                                    value={certificate.data[label.id]} onChange={e => {
                                    let c = cloneDeep(certificate);
                                    c.data[label.id] = e.target.value;
                                    setCertificate(c);
                                }}/>
                            </Form.Group>
                        </Col>
                    })}
                    </Row>
                    }
                </div> 
                                
                </Row>

                {  template &&
                <CertificateViewer backgroundImageUrl={template.backgroundImageUrl} labels={template.label}
                                images={template.images || []}
                                data={certificate.data}/>
                } 

            </div>
        
            <div className='Form-buttons-certificate'>
                <button className='cancel-certificate' onClick={()=>{navigate(`/templates/${selectedEvent}/certificates`)}}>Cancel</button>
                <Button
                    type="submit"
                    fullWidth onClick={handleSubmit}
                    disabled={loading}
                    variant="contained"
                    sx={{mt: 3, mb: 2}}
                >
                    {loading ? 'Please wait..' : 'Save'}
                </Button>
            </div>
        </div>
    </div>)
}


export default withRouter(EditCertificate)
