import React from "react";
import Heading from "../../v2/Components/Heading/Heading";
import { Link } from "react-router-dom";

const CreateCertificates = () => {
    return (
        <div className="right_container">
            <div className="dashboard">
                <div className='inner-content'>
                    {/* <Heading name={'Certificates'} refreshlink="#" link={`/certificates/new`}
                             linkName="Add New" url={"certificates"}/> */}

                    <div className="heading-for-certificate">
                        <h2>Certificates</h2>
                        <p>
                            Generate certificates from the here.
                            You may generate multiple certificates at once by using IMPORT option. 
                            Email will be sent to all the participants along with their certificates. 
                        </p>
                        <ul>
                            <li>
                                <Link to={`/certificates/new`}>
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg>
                                    Add New
                                </Link>
                            </li>
                            <li>
                                <Link to="/certificates/csv">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512"
                                        aria-hidden="true" focusable="false" height="1em" width="1em"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path>
                                    </svg>
                                    Import
                                </Link>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateCertificates;
