import React, {useEffect, useState} from 'react'
import {Col, Form, Row} from "react-bootstrap";
import SubmitButton from "../Main/Dashboard/components/SubmitButton";
import {useNavigate, useParams} from "react-router-dom";
import columnConfig from "../Main/Dashboard/columns";
import {Api} from "../../../helpers/Api";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import {connect} from "react-redux";

const ProgramForm = ({user, ...props}) => {
    const params = useParams();
    params.path = 'certificates';
    const [data, setData] = useState({});

    let config = columnConfig[params.path];
    const navigate = useNavigate();
    const [state, setState] = useState({
        loading: false,
        error: null,
        config: config,
        page: 1,
        limit: 20000,
        total: 0,
        show: false,
    });
    const [errors, setErrors] = useState({});


    const fetchOptions = async (c) => {
        let o = await Api.call(`${c.url}`);
        let options = [{"label": "", value: ""}]
        o.map(object => {
            options.push({label: object[c.label], value: object[c.key], object});
        })
        return options;
    }

    useEffect(async () => {
        const config = {...state.config};
        for (let i = 0; i < config.form.length; i++) {
            if (config.form[i].updateOptions) {
                config.form[i].options = config.form[i].updateOptions(config.form[i].allOptions, data);
            }
        }
        setState((s) => {
            return {...s, config}
        })
    }, [data])


    useEffect(async () => {
        if (!user) {
            return;
        }
        for (let i = 0; i < config.form.length; i++) {
            if (config.form[i].fetchOptions) {
                let options = await fetchOptions(config.form[i].fetchOptions);
                if (!config.form[i].updateOptions) {
                    config.form[i].options = options;
                }
                config.form[i].allOptions = options;
            }
        }
        setState((s) => {
            return {...s, config}
        })
    }, [config, user]);

    const renderError = (error)=>{
        if(!error){
            return <></>;
        }
        return <div className="error text-danger">{error}</div>
    }

    const renderLabel = (field)=>{
        return <Form.Label style={{color:"white"}}>{field.name} {field.required ? '*':''}</Form.Label>
    }

    const renderField = (field) => {
        let rendered = <></>;
        switch (field.type) {
            case 'textarea':
                rendered = <Col md={4}><Form.Group controlId={field.key} key={field.key}>
                    {renderLabel(field)}
                    <Form.Control as="textarea" rows={3} value={data[field.key]}
                                onChange={event => onChange(field.key, event.target.value)}
                                placeholder=""/>
                    {renderError(errors[field.key])}
                </Form.Group></Col>
                break;
            case 'select':
                rendered = <Col md={4}><Form.Group controlId={field.key} key={field.key}>
                    {renderLabel(field)}
                    <Form.Select
                        value={data[field.key] && typeof data[field.key] === "object" ? data[field.key].id : data[field.key]}
                        onChange={event => onChange(field.key, event.target.value)}
                    >
                        {field.options.map(o => {
                            if (typeof o === "object") {
                                return <option value={o.value} key={o.value}>{o.label}</option>
                            }
                            return <option value={o} key={o}>{o}</option>
                        })}
                    </Form.Select>

                </Form.Group>                    {renderError(errors[field.key])}
                </Col>
                break;
            case 'multiselect':
                if (field.options.length === 0 || (data['id'] && !data[field.key])) {
                    rendered = <></>;
                } else {
                    rendered = <Col md={4}><Form.Group controlId={field.key} key={field.key}>
                        {renderLabel(field)}
                        <Select options={field.options} isMulti={true}
                                defaultValue={field.serverToLocal(data[field.key])} onChange={(o) => {
                            onChange(field.key, field.localToServer(o))
                        }}/>
                    </Form.Group>                    {renderError(errors[field.key])}
                    </Col>
                }
                break;
            case 'date':
                rendered = <Col md={4}><Form.Group controlId={field.key} key={field.key}>
                    {renderLabel(field)}
                    <Form.Control type="date" value={data[field.key]}
                                onChange={event => onChange(field.key, event.target.value)}
                                placeholder=""/>
                </Form.Group>                    {renderError(errors[field.key])}
                </Col>
                break;
            case 'url':
                rendered = <Col md={4}><Form.Group controlId={field.key} key={field.key}>
                    {renderLabel(field)}
                    <Form.Control type="url" value={data[field.key]}
                                onChange={event => onChange(field.key, event.target.value)}
                                placeholder=""/>
                </Form.Group>                    {renderError(errors[field.key])}
                </Col>
            default:
                rendered = <Col md={4}><Form.Group controlId={field.key} key={field.key}>
                    {renderLabel(field)}
                    <Form.Control type="text" value={data[field.key]}
                                onChange={event => onChange(field.key, event.target.value)}
                                placeholder=""/>
                </Form.Group>                    {renderError(errors[field.key])}
                </Col>

        }
        if (field.condition && data[field.condition.key] !== field.condition.value) {
            rendered = <Form.Control type="hidden" value={data[field.key]}
                                    onChange={event => onChange(field.key, event.target.value)}
                                    placeholder=""/>

        }
        return rendered;
    }


    const loadData = (id) => {
        if (id === 'new') {
            return;
        }
        Api.call(`/${state.config.url}/${id}`).then(post => {
            setData(post)
            setTimeout(() => {
                console.log(data);
            }, 2000)
        }).catch(console.log).finally(() => {
            updateState({loading: false});
        });
    };

    useEffect(() => {
        if (params.id) {
            loadData(params.id)
        }

    }, [params.id])

    const validate = () => {
        let errors = {};
        state.config.form.map((field) => {
            if (field.condition && data[field.condition.key] !== field.condition.value) {
                return;
            }
            if (field.required && !data[field.key]) {
                errors[field.key] = `${field.name} is required`;
            }
        });
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const submit = (e) => {
        e.preventDefault();
        if (!validate()) {
            return;
        }
        if (data.id) {
            update();
        } else {
            create();
        }
    };

    const updateState = (obj) => {
        setState(s => {
            Object.keys(obj).forEach(key => {
                s[key] = obj[key];
            });
            return s;
        });
    }

    const update = () => {
        updateState({loading: true});
        Api.call(`/${state.config.url}/${data.id}`, "PUT", data).then(post => {
            setData(post)
            if(params.path === 'templates'){
                navigate(`/templates_2/${post.id}`);
            }else {
                navigate(`/${state.config.url}`);
            }
        }).catch(console.log).finally(() => {
            updateState({loading: false});
        })
    };
    const create = () => {
        updateState({loading: true});
        Api.call(`/${state.config.url}`, "POST", data).then(post => {
            setData([post]);
            if(params.path === 'templates'){
                navigate(`/templates_2/${post.id}`);
            }else {
                navigate(`/${state.config.url}`);
            }
        }).catch(console.log).finally(() => {
            updateState({loading: false});
        })
    };
    const onChange = (key, value) => {
        console.log({key, value})
        let d = {...data};
        d[key] = value;
        setData(d)
    };


    return <div className="right_container">
        <div className="dashboard">
            <div className='inner-content'>
                {/* <Heading link={"test"} linkName="Edit" /> */}
                <div className='form'>
                    {state.config.renderForm ?
                        state.config.renderForm() :
                        <Form autoComplete={'off'} onSubmit={submit}>
                            <Row>
                                {state.config.form.map((field) => {
                                    if (field.render) {
                                        let d = data[field.key];
                                        if (!d) {
                                            d = {};
                                        }
                                        return field.render(d, (d) => {
                                            onChange(field.key, d)
                                        })
                                    }

                                    return renderField(field)
                                })}
                            </Row>
                            <div className='Form-buttons'>
                                <button type="button" onClick={()=>{
                                    navigate(`/${state.config.url}`)
                                }} className='cancel'>Cancel</button>
                                <SubmitButton label={state.config.submitButtonText || 'Submit'} loading={state.loading}/>
                            </div>
                        </Form>
                    }
                </div>
            </div>
        </div>
    </div>
}


const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        dispatchLogin: (user) => dispatch({type: 'LOGIN', data: user})
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramForm)
