import * as React from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {Provider} from "react-redux";
import './App.css';
import {PersistGate} from "redux-persist/integration/react";
import {persistor, store} from "./redux/store";
import Login from "./views/auth/Login";
import Aside from "./views/v2/Components/Aside/Aside";
import Header from "./views/v2/Components/Header/Header";
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "./views/v2/Main/Home";
import Logout from "./views/auth/Logout";
import CSV from "./views/v2/Main/Certificates/CSV";
import UserList from "./views/v2/users/UserList";
import UserForm from "./views/v2/users/UserForm";
import SchoolList from "./views/v2/schools/SchoolList";
import SchoolForm from "./views/v2/schools/SchoolForm";
import DepartmentList from "./views/v2/departments/DepartmentList";
import DepartmentForm from "./views/v2/departments/DepartmentForm";
import EventList from "./views/v2/events/EventList";
import EventForm from "./views/v2/events/EventForm";
import CertificateList from "./views/v2/certificates/CertificateList";
import CertificateForm from "./views/v2/certificates/CertificateForm";
import EventFormStep2 from "./views/v2/events/EventFormStep2";
import CreateCertificates from "./views/main/certificates/CreateCertificates";
import PublicCertificateViewer from "./views/main/certificates/PublicCertificateViewer";
import TemplatesList from './views/v2/templates/templatesList';
import PastEvent from './views/v2/events/PastEvent';
import UpCommingEvents from './views/v2/events/UpComingEvents';

const theme = createTheme();

function App() {
    return <ThemeProvider theme={theme}>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Router>
                    <Routes>
                        <Route path="/" exact element={<Home/>}/>
                        <Route path="/auth/login" exact element={<Login/>}/>
                        <Route path="/logout" exact element={<Logout/>}/>
                        <Route path="/certificate/:id" exact element={<PublicCertificateViewer/>}/>
                        <Route path="/certificates/csv" exact element={
                            <div className="wrapper">
                                <Header/>
                                <Aside/>
                                <CSV/>
                            </div>
                        }/>

                        <Route path="/users" exact element={
                            <div className="wrapper">
                                <Header/>
                                <Aside/>
                                <UserList/>
                            </div>
                        }/>
                        <Route path="/brands" exact element={
                            <div className="wrapper">
                                <Header/>
                                <Aside/>
                                <SchoolList/>
                            </div>
                        }/>
                        <Route path="/departments" exact element={
                            <div className="wrapper">
                                <Header/>
                                <Aside/>
                                <DepartmentList/>
                            </div>
                        }/>
                        <Route path="/templates" exact element={
                            <div className="wrapper">
                                <Header/>
                                <Aside/>
                                <EventList/>
                            </div>
                        }/>
                        <Route path="/templates/:id/certificates" exact element={
                            <div className="wrapper">
                                <Header/>
                                <Aside/>
                                <CertificateList/>
                            </div>
                        }/>
                        <Route path="/certificates" exact element={
                            <div className="wrapper">
                                <Header/>
                                <Aside/>
                                <CreateCertificates/>
                            </div>
                        }/>
                        <Route path="/users/:id" exact element={
                            <div className="wrapper">
                                <Header/>
                                <Aside/>
                                <UserForm/>
                            </div>
                        }/>
                        <Route path="/brands/:id" exact element={
                            <div className="wrapper">
                                <Header/>
                                <Aside/>
                                <SchoolForm/>
                            </div>
                        }/>
                        <Route path="/certificates/:id" exact element={
                            <div className="wrapper">
                                <Header/>
                                <Aside/>
                                <CertificateForm/>
                            </div>
                        }/>
                        <Route path="/templates/:id" exact element={
                            <div className="wrapper">
                                <Header/>
                                <Aside/>
                                <EventForm/>
                            </div>
                        }/>
                        <Route path="/templates_2/:id/:templateId" exact element={
                            <div className="wrapper">
                                <Header/>
                                <Aside/>
                                <EventFormStep2/>
                            </div>
                        }/>
                        <Route path="/departments/:id" exact element={
                            <div className="wrapper">
                                <Header/>
                                <Aside/>
                                <DepartmentForm/>
                            </div>
                        }/>
                        <Route path="/eventTemplates/:id" exact element={
                            <div className="wrapper">
                                <Header/>
                                <Aside/>
                                <TemplatesList/>
                            </div>
                        }/>
                        <Route path="/postEvent" exact element={
                            <div className="wrapper">
                                <Header/>
                                <Aside/>
                                <PastEvent/>
                            </div>
                        }/>
                        <Route path="/UpCommingEvents" exact element={
                            <div className="wrapper">
                                <Header/>
                                <Aside/>
                                <UpCommingEvents/>
                            </div>
                        }/>
                    </Routes>
                </Router>
            </PersistGate>
        </Provider>
    </ThemeProvider>
}

export default App;
