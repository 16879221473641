import React, {Component} from 'react';
import {ExportToCsv} from 'export-to-csv';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

class Tbl extends Component {

    renderRow = (row, index) => {
        let columnIndex = 0;
        return <tr>
            {row.map(column => {
                return this.renderColumn(column, columnIndex++);
            })}</tr>
    };

    renderHeader = (columns) => {
        let columnIndex = 0;
        return columns.map(column => {
            return this.renderHeaderColumn(column, columnIndex++);
        })

    };
    export = () => {
        let columns = Object.keys(this.props.rows[0]).map(column => {
            return column;
        });
        let rows = (this.props.rows);
        console.log(rows.length, "Rows to export");

        var today = new Date();
        var date = today.getFullYear() + '_' + (today.getMonth() + 1) + '_' + today.getDate();
        var time = today.getHours() + "_" + today.getMinutes() + "_" + today.getSeconds();
        var dateTime = date + ' ' + time;
        let filename = `export_${dateTime}.csv`;

        rows = rows.map(row => {
            return Object.keys(row).map((key, index) => {
                if (typeof row[key] == 'object' && row[key]) {
                    return row[key].username ? row[key].username : row[key].id;
                } else {
                    return row[key];
                }
            });
        });


        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            filename: filename,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: false,
            headers: columns
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(rows);
        return;
        let csvContent = "data:text/csv;charset=utf-8,"
            + rows.map(e => e.join(",")).join("\n");
        csvContent = csvContent.replace('__actions__', '');
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);

        document.body.appendChild(link); // Required for FF
        link.setAttribute("download",);
        link.click();
    };

    renderColumn = (column, index) => {
        return <TableCell>{column}</TableCell>
    };

    renderHeaderColumn = (column, index) => {
        return <TableCell>{column.name}</TableCell>
    };

    renderLoading = () => {
        return <TableRow>
            <TableCell colSpan={this.props.columns.length}>Loading...</TableCell>
        </TableRow>
    };

    render() {
        let rowIndex = 0;

        let props = this.props;
        if (props.renderRow) {
            this.renderRow = props.renderRow;
        }
        if (props.renderColumn) {
            this.renderColumn = props.renderColumn;
        }
        if (props.renderHeaderColumn) {
            this.renderHeaderColumn = props.renderHeaderColumn;
        }
        return (
            <div className='responsive-table'>
                <TableContainer  component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            {this.renderHeader(props.columns)}
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {this.props.loading ?
                            this.renderLoading() :
                            props.rows.map(row => {
                                return this.renderRow(row, rowIndex++);
                            })
                        }
                        </TableBody>
                    </Table>
                </TableContainer>
                {/*<Pagination total={this.props.total} currentPage={this.props.currentPage} perPage={this.props.perPage}
                            onChangePerPage={(value) => {
                                if (this.props.onChangePerPage) {
                                    this.props.onChangePerPage(value)
                                }
                            }} onExport={() => {

                    this.export();
                }
                }
                            onPaginate={this.props.onPaginate}/>*/}
            </div>
        );
    }
}

export default Tbl;


