import React from 'react'
import {Link} from "react-router-dom";

const HeaderDropDown =(props)=> {
        return (
            <div className='header-drop-down'>
                <ul>
                    <li>
                        <Link to="/auth/login">Logout</Link>
                    </li>
                </ul>
            </div>
        )

};

export default HeaderDropDown;
