import {combineReducers} from "@reduxjs/toolkit";

const INITIAL_STATE = {};

function userReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case "LOGIN":
            return {...state, ...action.data}
        case "UPDATE_USER":
            return {...state, user: action.data}
        default:
            return state
    }
}

const rootReducer = combineReducers({
    user: userReducer
});

export default rootReducer;
