import React, {useEffect, useRef, useState} from 'react'
import {Link} from "react-router-dom";
import HeaderDropDown from './components/HeaderDropDown';
import {connect} from "react-redux";

// Hook
function useOnClickOutside(ref, buttonRef, handler) {
    useEffect(
        () => {
            const listener = (event) => {
                // Do nothing if clicking ref's element or descendent elements
                if (!ref.current || ref.current.contains(event.target) || (buttonRef?.current?.contains(event.target))) {
                    return;
                }
                handler(event);
            };
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            return () => {
                document.removeEventListener("mousedown", listener);
                document.removeEventListener("touchstart", listener);
            };
        },
        // Add ref and handler to effect dependencies
        // It's worth noting that because passed in handler is a new ...
        // ... function on every render that will cause this effect ...
        // ... callback/cleanup to run every render. It's not a big deal ...
        // ... but to optimize you can wrap handler in useCallback before ...
        // ... passing it into this hook.
        [ref, handler]
    );
}

const Header = ({user, ...props}) => {

    const [showDropdown, setShowDropdown] = useState(false);

    const ref = useRef();
    const buttonRef = useRef();

    useOnClickOutside(ref, buttonRef, () => setShowDropdown(false));

    return (
        <div className="header">
            <div className="logo">
                <Link to="/">
                    <img src="../../../../images/chitkara-logo1.png" alt="logo" width="90"  />
                    <img src="../../../../images/chitkara-logo.png" alt="logo" width="52" />
                </Link>
            </div>
            <div className='userProfile'>
                <div onClick={() => setShowDropdown(!showDropdown)} style={{color:'#FFFFFF', cursor:'pointer', fontWeight:'bold'}}>{user?.user?.name}</div>
                {showDropdown &&
                    <div ref={ref} >
                        <HeaderDropDown />
                    </div>
                }
            </div>
        </div>
    )

};

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        dispatchUpdate: (user) => dispatch({type: 'UPDATE_USER', data: user})
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header)
