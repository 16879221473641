import * as React from 'react';
import {useEffect} from 'react';


const Logout = ({dispatchLogin}) => {
    useEffect(() => {
        window.localStorage.clear();
        window.location.href = "/auth/login";
    }, []);



    return <></>
}

export default Logout;
