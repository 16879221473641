import React, {useCallback, useEffect, useState} from 'react'
import Table from "../Components/Table/Table";
import {Link} from "react-router-dom";
import {Api} from "../../../helpers/Api";
import Heading from "../Components/Heading/Heading";
import columnConfig from "../Main/Dashboard/columns";
import {connect} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";

const PastEvent = ({user, ...props}) => {

    const params = {path: 'template_4'};
    const navigate = useNavigate();
    let config = columnConfig[params.path];
    const [state, setState] = useState({
        loading: true,
        error: null,
        config: config,
        page: 1,
        limit: 20000,
        total: 0,
        show: false,
    });

    const [posts, setPosts] = useState([]);
    const [total, setTotal] = useState(-1);


    const loadData = useCallback(() => {
        setState(s => {
            return {...s, loading: true}
        });
        let start = 0;
        const limit = state.limit;
        const page = state.page;
        let today = new Date();
        let month = `${today.getMonth()+1}`;
        let day = `${today.getDate()}`;
        if(month.length <= 1)
        {
            month = '0'+month;
        }
        if(day.length <= 1)
        {
            day = '0'+day;
        }
        let date = today.getFullYear()+'-'+month+'-'+day;
        start = (page - 1) * limit;
        Api.call(`/${config.url}/count`).then(response => {
            setTotal(response);
        });
        
        Api.call(`/getpostEvent?end_date=${date}&_sort=id:DESC&_limit=${limit}&_start=${start}`).then(response => {
            setPosts(response)
        }).catch(console.log).finally(_ => {
            setState(s => {
                return {...s, loading: false}
            });
        });
    }, [config.url, state.page, state.limit]);

    const onPaginate = (page) => {
        setState({...state, page: page, loading: true})
    };

    useEffect(() => {
        if (!user) {
            return;
        }
        loadData()
    }, [loadData, params.path, user]);


    const parsePatient = (post) => {
        let row = state.config.columns.map((column) => {
            if (column.accessor) {
                return column.accessor(post);
            }
            return post[column.key];
        });
        console.log({row});
        return row;
    };

    const renderRow = (row, index) => {
        let columnIndex = 0;
        let patient = parsePatient(row);
        return <tr key={index}>
            {patient.map(column => {
                return renderColumn(column, columnIndex++, index, patient, row);
            })}</tr>
    };

    const selectAll = () => {
        let allSelected = !state.allSelected;
        let row = state.row.map(patient => {
            patient.selected = allSelected;
            return patient;
        });
        setState({...state, row: row, allSelected: allSelected});
    };

    const onDelete = (event, index) => {
        event.preventDefault();
        if (!window.confirm('Are you sure you want to delete this item?')) {
            return;
        }
        let post = posts[index];
        Api.call(`/${config.url}/${post.id}`, "DELETE").then(response => {
            loadData();
        })
    };

    const onUp = (event, index) => {
        event.preventDefault();
        setState({...state, loading: true})
        let post = posts[index];
        Api.call(`/${config.url}/${post.id}/move/up`, "POST").then(response => {

        }).catch(() => {

        }).finally(loadData)
    };

    const onDown = (event, index) => {
        setState({...state, loading: true})
        event.preventDefault();

        let post = posts[index];
        Api.call(`/${config.url}/${post.id}/move/down`, "POST").then(response => {

        }).catch(() => {

        }).finally(loadData)
    };

    const renderColumn = (column, index, rowIndex, row, originalRow) => {
        if (index === row.length - 1) {

            if (state.config.renderActions) {
                return state.config.renderActions(column, index, rowIndex, row, originalRow, onUp , onDown,onDelete, state, config);
            }// } else {
            //     return <td key={index}>
            //         <ul>
            //             <li>
            //                 <Link to={`/${config.url}/${originalRow.id}`}>
            //                     <img width="16px" src="../../../images/edit-icon.png" alt="edit"/>
            //                 </Link>
            //             </li>
            //             <li>
            //                 <Link to="/" onClick={e => onDelete(e, rowIndex)}>
            //                     <img width="16px" src="../../../images/garbage.png" alt="edit"/>
            //                 </Link>
            //             </li>
            //         </ul>
            //     </td>
            // }
        }
        if (typeof column === "boolean") {
            return <td key={index}><span style={{color: column ? "green" : "red"}}>{column ? "Yes" : "No"}</span></td>
        }
        console.log("Rendering ", column)
        return <td key={index}>{column}</td>
    };
    const renderHeaderColumn = (column, index) => {

        return (
            <th key={index}>
                {column.name}
            </th>
        );
    };

    if(state.loading === false && posts.length === 0)
    {
        return (
            <div className="right_container">
                <div className="dashboard">
                    <div className='inner-content'>
                        {/* <Heading name={'Certificates'} refreshlink="#" link={`/certificates/new`}
                                 linkName="Add New" url={"certificates"}/> */}
                        <div className="heading-for-certificate">
                            <h2>{`Events`}</h2>
                            <p>
                                There are no past events.
                            </p>
                            <ul>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    else
    {
    return (
        <div className="right_container">
            <div className="dashboard">
                <div className='inner-content'>
                    {/* <Heading name={config.name} onRefresh={loadData} refreshlink="#" link={`/${config.url}/new`}
                            linkName="Add New" url={params.path}/> */}
                            <Heading name={config.name}  linkName="Refresh" link={loadData}  />
                    <div className='commen-table'>
                        <Table renderColumn={renderColumn} loading={state.loading}
                            renderRow={renderRow} renderHeaderColumn={renderHeaderColumn}
                            columns={state.config.columns} cardFooter="cardFooter" total={total}
                            currentPage={state.page} perPage={state.limit}
                            rows={posts} selected="Invite Selected" onPaginate={onPaginate}
                            onChangePerPage={(limit) => {
                            setState({...state, limit});
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        dispatchLogin: (user) => dispatch({type: 'LOGIN', data: user})
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PastEvent)
