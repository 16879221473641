const extractError = (e)=>{
    let body = e.response.data;

    if(body.message && body.message.length && body.message[0].messages && body.message[0].messages.length){
        return body.message[0].messages[0].message;
    }
    return body.error;
};

export  {
    extractError
}
