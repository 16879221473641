import * as React from 'react';
import {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {withRouter} from "../../../hoc/withRouter.ts";
import {Api} from "../../../helpers/Api";
import CertificateViewer from "./CertificateViewer";

const PublicCertificateViewer = () => {
    const [template, setTemplate] = useState(null);
    const [certificate, setCertificate] = useState({data: {}, template: {data: {}}});


    const {id} = useParams();

    useEffect(() => {
        Api.getCertificate(id).then(response => {
            setCertificate(response);
            setTemplate(response.templateData);
        })
    }, [id])


    return (<div>
        <div className='certificate-add'>
            {JSON.stringify(template)}
            {template ?
                <CertificateViewer id={certificate.id} backgroundImageUrl={template.data.backgroundImageUrl} qr={template.qr} labels={template.data.labels}
                                images={template.data.images || []}
                                data={certificate.data}/>
                :
                <span>Loading...</span>
            }

        </div>
    </div>)
}


export default withRouter(PublicCertificateViewer);
