import {createStore} from 'redux'
import {persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import rootReducer from './reducers/rootReducer'

const persistConfig = {
    key: 'data',
    storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const ret = () => {
    let store = createStore(persistedReducer)
    let persistor = persistStore(store)
    return {store, persistor}
}

export default ret;
