import TemplateBuilder from "../../../main/templates/TemplateBuilder";
import EditCertificate from "../../../main/certificates/EditCertificate";
import {Link, useNavigate} from "react-router-dom";
import React from "react";

const columnConfig = {
    brands: {
        url: "brands",
        name: "Schools",
        columns: [
            {name: "Name", key: "name"},
            {
                name: "Created By", accessor: (object) => {
                    return object?.owner.name;
                }
            },
            {
                name: "Created", accessor: (object) => {
                    return object?.created_at.substr(0,10);
                }
            },
            {
                name: "Updated", accessor: (object) => {
                    return object?.updated_at.substr(0,10);
                }
            },
            {name: "Actions", key: "actions"},
        ],
        form: [
            {name: "Name", key: "name", type: 'text', required: true},
            {name: "About", key: "about", type: 'textarea'},
            {name: "Address Line 1", key: "address_line_1", type: 'textarea'},
            {name: "Address Line 2", key: "address_line_2", type: 'textarea'},
            {name: "Country", key: "country", type: 'select', options: ["", "India", "Australia"]},
            {name: "State", key: "state", type: 'select', options: ["", "Other", "Haryana", "Punjab"]},
            {name: "City", key: "city", type: 'text'},
            {name: "Pincode", key: "pincode", type: 'text'},
            {name: "Mobile", key: "mobile", type: 'text'},
            {name: "Email", key: "email", type: 'text'},
            {name: "Website", key: "website", type: 'text'},
            {name: "Facebook", key: "facebook", type: 'text'},
            {name: "LinkedIn", key: "linkedin", type: 'text'},
            {name: "Instagram", key: "instagram", type: 'text'},
            {name: "Twitter", key: "twitter", type: 'text'},
        ]
    },
    departments: {
        url: "departments",
        name: "Departments",
        columns: [
            {name: "Name", key: "name"},
            {
                name: "School", accessor: (object) => {
                    if(object?.brandName){
                        return object?.brandName
                    } 
                    else{
                        return "No School";
                    }
                }
            },
            {
                name: "Created By", accessor: (object) => {
                    return object?.ownerName;
                }
            },
            {
                name: "Created_At", accessor: (object) => {
                    return object?.created_at.substr(0,10);
                }
            },
            {
                name: "Updated_At", accessor: (object) => {
                    return object?.updated_at.substr(0,10);
                }
            },
            {name: "Actions", key: "actions"},
        ],
        form: [
            {name: "Name", key: "name", type: 'text', required: true},

            {
                name: "School",
                key: "brand",
                type: 'select',
                options: [],
                fetchOptions: {url: "/brands", key: "id", label: "name"},
            },
        ]
    },
    users: {
        url: "users",
        name: "Users",
        columns: [
            {name: "Name", key: "name"},
            {name: "Email", key: "email"},
            // {
            //     name: "Created By", accessor: (object) => {
            //         return object?.owner?.name;
            //     }
            // },
            {
                name: "Created", accessor: (object) => {
                    return object?.created_at.substr(0,10);
                }
            },
            {
                name: "Updated", accessor: (object) => {
                    return object?.updated_at.substr(0,10);
                }
            },
            {name: "Actions", key: "actions"},
        ],
        form: [
            {name: "Name", key: "name", type: 'text'},
            {name: "Username", key: "username"},
            {name: "Email", key: "email"},
            {name: "Contact-Number", key: "ContactNumber"},
            {name: "Password", key: "password"},
            
            {name: "Role", key: "role", type: 'select', options: [{key:'',value:''}, {value:2,label:'ROLE_USER'}, {value:1,label:'ROLE_ADMIN'}]},
            {
                name: "School",
                key: "brands",
                type: 'brandselect',
                options: [],
                fetchOptions: {url: "/brands", key: "id", label: "name"},
                serverToLocal: (values) => {
                    console.log("serverToLocal")
                    console.log(values);
                    if (!values) {
                        return [];
                    }
                    
                    // return values.map(v => {
                        
                    //     if (typeof v === "string") {
                    //         return v;
                    //     }
                        
                    return values.label ? values : {label: values.name, value: values.id}
                },
                localToServer: (values = null) => {
                    return values.map(v => v.value)
                },
            },
            {
                name: "Departments",
                key: "departments",
                type: 'depselect',
                options: [],
                updateOptions: (options, data) =>{
                    let o =[];
                    if(!options){
                        return [];
                    }
                    console.log("brands");
                    
                    options.map((option) =>{
                    if (option.object?.brand?.id == data['brands'])
                    {  
                        o.push(option);
                    }
                    if (option.object?.brand == null && data['brands'] == "")
                    {  
                        o.push(option);
                    }
                    });  
                    return o ;
                },
                serverToLocal: (values,data) => {
                    
                    if (!values) {
                        return [];
                    }

                    let d =  values.map(v => {
                        if (typeof v === "string") {
                            return v;
                        }
                        return v.label ? v : {label: v.name, value: v.id}
                    })
                    return d;
                },
                
                localToServer: (values) => {
                    let a = [];
                    values.map(v=>
                        a.push({label:v.label, value: v.value})
                        );
                    return a;
                },
                fetchOptions: {url: "/departments", key: "id", label: "name"}
            },
        ]
    },
    templates: {
        url: "templates",
        name: "Events",
        columns: [
            {name: "Name", key: "name"},
            {
                name: "Created By", accessor: (object) => {
                    return object?.ownerName;
                }
            },
            {
                name: "Created_At", accessor: (object) => {
                    return object?.created_at.substr(0,10)
                }
            },
            {
                name: "Updated_At", accessor: (object) => {
                    return object?.updated_at.substr(0,10)
                }
            },
            {
                name: "Templates", accessor: (object) => {
                    return (<Link to={`/eventTemplates/${object.id}`}>
                            <p style={{color: "white", textDecorationLine:'underline'}}>Template</p>
                        </Link>)
                }
            },
            {name: "Actions", key: "actions"},
        ],
        submitButtonText: 'Save',
        form: [
            {name: "Name", key: "name", type: 'text'},
            {
                name: "Select School",
                key: "brand",
                type: 'select',
                options: [],
                fetchOptions: {url: "/brands", key: "id", label: "name"},
            },
            {
                name: "Select Department",
                key: "department",
                type: 'select',
                options: [],
                updateOptions: (options, data) => {
                    if (!options) {
                        return [];
                    }
                    let o = [];
                    o.push({value:"", label:"Select Department"});
                 
                    options.map(option => {
                        if (option.object?.brand?.id == data.brand || (option.object.brand && option.object?.brand.id == data.brand?.id)) {
                            o.push(option);
                        }
                        if (option.object?.brand == null && data['brand'] == "")
                        {  
                        o.push(option);
                        }
                    })
                    return o;
                },
                fetchOptions: {url: "/departments", key: "id", label: "name"},
            },
            {
                name: "Event Type",
                key: "type",
                type: 'select',
                options: ["","Workshop", "Webinar", "Hackathon", "Conference", "Bootcamp", "Course", "Training", "Internship", "Job", "Student Ambassador", "Social Event", "Charity Event", "Competition", "Summit", "Other"]
            },
            {name: "Enable QR", key: "qr", type: 'boolean'},
            {name: "About", key: "about", type: 'textarea'},
            {name: "Start Date", key: "start_date", type: 'date', required: true},
            {name: "End Date", key: "end_date", type: 'date', required: true},
            {name: "Location", key: "location", type: 'select', options: ["","Online", "Offline"], required: true},
            {
                name: "Address Line 1",
                key: "address_line_1",
                type: 'textarea',
                condition: {key: "location", value: "Offline"},
                required: true
            },
            {
                name: "Address Line 2",
                key: "address_line_2",
                type: 'textarea',
                condition: {key: "location", value: "Offline"}
            },
            {
                name: "Country",
                key: "country",
                type: 'select',
                options: ["India", "Australia"],
                condition: {key: "location", value: "Offline"}
            },
            {
                name: "State",
                key: "state",
                type: 'select',
                options: ["","Other", "Haryana", "Punjab"],
                condition: {key: "location", value: "Offline"}
            },
            {name: "City", key: "city", type: 'text', condition: {key: "location", value: "Offline"}},
            {name: "Pincode", key: "pincode", type: 'text', condition: {key: "location", value: "Offline"}},
            {name: "Website", key: "website", type: 'url',},
            {name: "Facebook URL", key: "facebook", type: 'url'},
            {name: "Linkedin URL", key: "linkedin", type: 'url'},
        ],
    },
    templates_2: {
        url: "templates",
        name: "Templates",
        columns: [{name: "Name", accessor: (object) => {
            return object?.TemplateName;
        }
        },
        {
            name: "Updated_By", accessor: (object) => {
                return object?.Updated_By;
        }
        },
        {
            name: "Created_At", accessor: (object) => {
                return object?.Created_At;
        }
        },
        {
            name: "Updated_At", accessor: (object) => {
                return object?.Updated_At;
        }
        },
        {
            name: "Action", accessor: (object) => {
                return object?.owner?.name;
        }
        },
        ],
        form: [
            {name: "TemplateName", key: "template_name", type: 'text', required: true},
            {
                name: "TemplateType",
                key: "template_type",
                type: 'conOption',
                options: ["","Participant", "Winner", "Coordinator", "Organizer", "Volunteer", "Ambassador", "Speaker", "Mentor", "Judge", "Award", "Achievement", "Completion", "Experience", "Grade", "Offer letter", "Other"],
                required: true
            },
            {
                name: "Build Template", key: "data", render: (templateName,data, setData) => {
                    return <div className="template-selecter"><TemplateBuilder  
                                                                            TemplateName = {templateName} 
                                                                            labels={data.labels || []}
                                                                            setLabels={(l) => {
                                                                                let d = {...data};
                                                                                d.labels = l;
                                                                                setData(d);
                                                                            }}
                                                                            images={data.images || []}
                                                                                setImages={(l) => {
                                                                                    let d = {...data};
                                                                                    d.images = l;
                                                                                    setData(d);
                                                                                }}
                                                                            backgroundImageUrl={data.backgroundImageUrl}
                                                                                setBackgroundImageUrl={url => {
                                                                                    let d = {...data};
                                                                                    d.backgroundImageUrl = url;
                                                                                    setData(d);
                                                                                }
                                                                                }/></div>
                }
            },
        ],
    },
    template_3:{
       url: "templates",
       name:"UpCommingEvent",
       columns:[
        {
            name:"Name", key:"name"
        },
        {
            name:"Start_Date", accessor: (object) =>
            {
                return object.start_date;
            }
        },
        {
            name:"End_Date", accessor: (object) => 
            {
                return object.end_date;
            }
        },
        ] 
    },
    template_4:{
        url: "templates",
        name:"Post-Event",
        columns:[
        {
            name:"Name", key:"name"
        },
        {
            name:"Start_Date", accessor: (object) =>
            {
                return object.start_date;
            }
        },
        {
            name:"End_Date", accessor: (object) => 
            {
                return object.end_date;
            }
        },
        ] 
    },
    certificates: {
        url: "certificates",
        name: "Certificates",
        columns: [
            {name: "Name", accessor: (object) => {
                    return object?.templateData.TemplateName;
                }
            },
            {
                name: "Event", accessor: (object) => {
                    return object?.template?.name;
                }
            },
            {
                name: "Generated By", accessor: (object) => {
                    return object?.owner?.name;
                }
            },
            {
                name: "Created_At", accessor: (object) => {
                    return object?.created_at.substr(0,10)
                }
            },
            {
                name: "Actions", key: "actions",
            },
        ],
        form: [],
        renderForm: () => {
            return <EditCertificate/>;
        },
        renderActions__unused: (column, index, rowIndex, row, originalRow, onUp, onDown, onDelete, config) => {
            return <td key={'actions'}>
                <ul>

                    <li>
                        <Link to="/" onClick={e => onUp(e, rowIndex)}>
                            <img width="16px" src="../../../../images/up-arrow.png" alt="move up"/>
                        </Link>
                    </li>
                    <li>
                        <Link to="/" onClick={e => onDown(e, rowIndex)}>
                            <img width="16px" src="../../../images/down-arrow.png" alt="move down"/>
                        </Link>
                    </li>
                    <li>
                        <Link to={`/${config.url}/${originalRow.id}`}>
                            <img width="16px" src="../../../images/edit-icon.png" alt="edit"/>
                        </Link>
                    </li>
                    <li>
                        <Link to="/" onClick={e => onDelete(e, rowIndex)}>
                            <img width="16px" src="../../../images/garbage.png" alt="edit"/>
                        </Link>
                    </li>
                </ul>
            </td>;
        }
    },
}

export default columnConfig;
