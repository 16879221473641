import React, {useEffect, useState} from 'react';
import {Image, Label, Layer, Stage, Tag, Text} from 'react-konva';
import {Button} from "@mui/material";
import {Api} from "../../../helpers/Api";
import {SketchPicker} from "react-color";
import Select from "react-select";


const TemplateBuilder = ({labels, setLabels, images, setImages, backgroundImageUrl, setBackgroundImageUrl,Index}) => {

    const [backgroundImage, setBackgroundImage] = useState(null);
    const [imageMaps, setImageMaps] = useState({});
    const [selectedLabelIndex, setSelectedLabelIndex] = useState(-1);
    const [selectedImageIndex, setSelectedImageIndex] = useState(-1);

    const addLabel = (type = 'label') => {
        const label = {
            id: labels.length.toString(),
            x: 100,
            y: 100,
            draggable: true,
            text: `Label`,
            fontSize: 20,
            lineHeight: 1.2,
            fontFamily: 'Arial',
            padding: 10,
            color: '#000000'
        };
        setLabels([...labels, label]);
    }

    const addImage = (url) => {
        const label = {
            id: 'image-' + images.length.toString(),
            x: 100,
            y: 100,
            width: 100,
            height: 100,
            draggable: true,
            url: url,
            fontSize: 20,
            lineHeight: 1.2,
            padding: 10,
            color: '#000000'
        };
        setImages([...images, label]);
    }

    const handleDragStart = (e) => {
        const id = e.target.id();
        setLabels(
            labels.map((star) => {
                return {
                    ...star,
                    isDragging: star.id === id,
                };
            })
        );
    };

    const handleDragStartImage = (e) => {
        const id = e.target.id();
        setImages(
            images.map((star) => {
                return {
                    ...star,
                    isDragging: star.id === id,
                };
            })
        );
    };

    const handleDragEnd = (e) => {
        setLabels(
            labels.map((star) => {
                return {
                    ...star,
                    isDragging: false,
                    ...{
                        x: e.target.id() === star.id ? e.target.x() : star.x,
                        y: e.target.id() === star.id ? e.target.y() : star.y,
                    }
                };
            })
        );
    };

    const handleDragEndImage = (e) => {
        setImages(
            images.map((star) => {
                return {
                    ...star,
                    isDragging: false,
                    ...{
                        x: e.target.id() === star.id ? e.target.x() : star.x,
                        y: e.target.id() === star.id ? e.target.y() : star.y,
                    }
                };
            })
        );
    };

    const handImageLoad = (e) => {
        setBackgroundImage(e.target)
    }
    useEffect(() => {
        if (backgroundImageUrl) {
            const image = new window.Image();
            image.src = backgroundImageUrl;
            image.addEventListener('load', handImageLoad);
            return () => {
                image.removeEventListener('load', handImageLoad)
            };
        }
    }, [backgroundImageUrl]);

    useEffect(() => {
        images.forEach(({url}, index) => {
            const image = new window.Image();
            image.src = url;
            image.addEventListener('load', (e) => {
                setImageMaps(i => ({...i, [index]: e.target}))
            });
        })
    }, [images]);

    const triggerFileClick = () => {
        document.getElementById("backgroundImageFile"+Index).click();
        
    }

    const triggerImageFileClick = () => {
        document.getElementById("imageFile"+Index).click();
    }

    const onBackgroundImageSelect = (file) => {

        document.getElementById("backgroundImageFile"+Index).value = '';

        const formData = new FormData();

        formData.append('files', file);

        Api.uploadFile(formData).then(response => {
            setBackgroundImageUrl(`${Api.base_url}${response[0].url}`)
        });
    };

    const onImageSelect = (file) => {

        document.getElementById("backgroundImageFile"+Index).value = '';

        const formData = new FormData();

        formData.append('files', file);

        Api.uploadFile(formData).then(response => {
            addImage(`${Api.base_url}${response[0].url}`)
        });
    };

    return (
        <div className='mt-3 template-inner-box'>
            <div className='template-boxes'>
                <div className="template-image-box">
                    <input type="file" id={`backgroundImageFile${Index}`} onChange={e => {
                        onBackgroundImageSelect(e.target.files[0])
                    }} accept="image/*" style={{display: 'none'}}/>
                    <input type="file" id={`imageFile${Index}`} onChange={e => {
                        onImageSelect(e.target.files[0])
                    }} accept="image/*" style={{display: 'none'}}/>
                    <Stage width={800} height={700}>
                        <Layer>
                            <Image image={backgroundImage} width={800} height={700}/>
                        </Layer>
                        <Layer>
                            {images.map((star, index) => (
                                <Image
                                    id={star.id}
                                    key={star.key}
                                    x={star.x}
                                    y={star.y}
                                    width={star.width}
                                    height={star.height}
                                    image={imageMaps[index]}
                                    opacity={1}
                                    draggable
                                    onClick={e => {
                                        setSelectedImageIndex(index);
                                        setSelectedLabelIndex(-1)
                                    }}
                                    rotation={star.rotation}
                                    onDragStart={handleDragStartImage}
                                    onDragEnd={handleDragEndImage}
                                >
                                </Image>
                            ))}

                        </Layer>
                        <Layer>
                            {labels.map((star, index) => (
                                <Label
                                    id={star.id}
                                    key={star.key}
                                    x={star.x}
                                    y={star.y}
                                    opacity={1}
                                    draggable
                                    onClick={e => {
                                        setSelectedLabelIndex(index);
                                        setSelectedImageIndex(-1)
                                    }}
                                    rotation={star.rotation}
                                    onDragStart={handleDragStart}
                                    onDragEnd={handleDragEnd}
                                >
                                    {selectedLabelIndex === index &&
                                    <Tag strokeWidth={1} // border width
                                        stroke="#ffffff"/>
                                    }
                                    <Text text={star.text} fill={star.color} fontFamily={star.fontFamily || "Arial"}
                                        fontSize={Number(star.fontSize)}
                                        style={{color: '#ffffff'}}/>
                                </Label>
                            ))}
                        </Layer>
                    </Stage>
                </div>
                {selectedLabelIndex > -1 &&
                <LabelProperties label={labels[selectedLabelIndex]} onChange={(v) => {
                    setLabels(labels.map((label) => {
                        if (label.id === v.id) {
                            return v;
                        }
                        return label;
                    }))
                }}/>
                }
                {selectedImageIndex > -1 &&
                <ImageProperties image={images[selectedImageIndex]} onChange={(v) => {
                    setImages(images.map((label) => {
                        if (label.id === v.id) {
                            return v;
                        }
                        return label;
                    }))
                }}/>
                }
            </div>
            <Button className='add-label' onClick={triggerFileClick}>Browse Template Design</Button>
            <Button className="add-label" onClick={triggerImageFileClick}>Add Signature</Button>
            <Button className="add-label" onClick={addLabel}>Add Label</Button>   
        </div>
    );
};

const LabelProperties = ({label, onChange}) => {
    return <div className='template-right'>
        <div className="picker-label">
            <span>Label:</span>
            <input value={label.text} onChange={(e) => {
                onChange({...label, text: e.target.value})
            }}/>
        </div>
        <div className="picker-label">
            <span>Color:</span>
            <ColorPicker value={label.color} onChange={(color) => {
                onChange({...label, color})
            }}/>
        </div>
        <div className="picker-label">
            <span>Font Size:</span>
            <input type="number" value={label.fontSize} onChange={(e) => {
                onChange({...label, fontSize: e.target.value})
            }}/>
        </div>
        <div className="picker-label">
            <span>Font Family:</span>
            <Select options={[{label: "Arial", value: "Arial"},{label: "Tahoma", value: "Tahoma"}]} isMulti={false}
                    defaultValue={{label: (label.fontFamily || "Arial"), value: (label.fontFamily || "Arial")}}
                    onChange={(o) => {
                        onChange({...label, fontFamily: o.value})
                    }}/>
        </div>

    </div>
}
const ImageProperties = ({image, onChange}) => {
    return <div className='template-right'>
        <div className="picker-label">
            <span>Width:</span>
            <input type="number" value={image.width || 100} onChange={(e) => {
                onChange({...image, width: e.target.value})
            }}/>
        </div>
        <div className="picker-label">
            <span>Height:</span>
            <input type="number" value={image.height || 100} onChange={(e) => {
                onChange({...image, height: e.target.value})
            }}/>
        </div>
    </div>
}

const ColorPicker = ({value, onChange}) => {
    const [show, setShow] = useState(false);
    if (!show) {
        return <div style={{width: 50, height: 50, cursor: 'pointer', backgroundColor: value}}
                    onClick={() => setShow(true)}/>
    }
    if (show) {
        return <SketchPicker
            color={value}
            onChangeComplete={c => {
                setShow(false)
                onChange(c.hex)
            }}
        />
    }
}
export default TemplateBuilder;
