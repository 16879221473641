import axios, {AxiosResponse} from 'axios';
import {store} from "../redux/store";


const responseBody = (response: AxiosResponse) => response.data;


const createInstance = (authorize = true, multipart = false) => {

    let token = store.getState().user.jwt;

    const headers: Record<string, string> = {
        Accept: 'application/json',
    };
    if (authorize && token) {
        headers.Authorization = `Bearer ${token}`;
    }
    if (multipart) {
        headers['content-type'] = 'multipart/form-data';
    }
    return axios.create({
        baseURL: Api.base_url,
        timeout: 15000,
        headers
    });
}
const requests = {
    get: (url: string, authorize = true) => {
        return createInstance(authorize).get(url).then(responseBody)
    },
    post: (url: string, body: {}, authorize = true, multipart = false) => {
        return createInstance(authorize, multipart).post(url, body).then(responseBody)
    },
    put: (url: string, body: {}, authorize = true) => {
        return createInstance(authorize).put(url, body).then(responseBody)
    },
    delete: (url: string, authorize = true) => {
        return createInstance(authorize).delete(url).then(responseBody)
    },
};

export const Api = {
    base_url: 'https://backend.certificates.itmatic.io',
    // base_url: 'http://localhost:1337',

    updateProfile: (params: Map<string, any>) => {
        return requests.post("/users/me", params);
    },
    getProfile: () => {
        return requests.get("/users/me");
    },

    login: (params: Map<string, string>) => {
        return requests.post('/auth/local', params, false);
    },

    changePassword(params: Map<string, any>) {
        return requests.post('/auth/reset-password', params, false);
    },

    uploadFile(data: FormData) {
        return requests.post(`/upload`, data, true, true);
    },


    register(params: Map<string, any>) {
        return requests.post('/auth/local/register', params, false);
    },

    forgetPassword(params: Map<string, any>) {
        return requests.post('/auth/forgot-password', params, false);
    },

    newPassword(params: Map<string, any>) {
        return requests.post('/password', params, false);
    },

    getTemplates() {
        return requests.get("/templates");
    },
    call(path:string, method = "GET", data: Map<string, any>) {
        switch (method){
            case 'GET':
                return requests.get(path);
            case 'POST':
                return requests.post(path, data);
            case 'PUT':
                return requests.put(path, data);
            case 'DELETE':
                return requests.delete(path);
        }
    },

    updateTemplate(params: Map<string, string>) {
        // @ts-ignore
        if(params.id){
            // @ts-ignore
            return requests.put(`/templates/${params.id}`, params);
        }
        return requests.post(`/templates`, params);
    },
    updateCertificate(params: Map<string, string>) {
        // @ts-ignore
        if(params.id){
            // @ts-ignore
            return requests.put(`/certificates/${params.id}`, params);
        }
        return requests.post(`/certificates`, params);
    },
    getTemplate(id: string) {
        return requests.get(`/templates/${id}`);
    },
    getCertificate(id: string) {
        return requests.get(`/certificates/${id}`);
    },
    getCertificates() {
        return requests.get(`/certificates`);
    }
};
