import React from 'react'
import Nav from "../Nav/Nav";

class Aside extends React.Component {


    render() {
        return (
            <div className="aside">
                <div className="sidebar" style={{width:'120px'}}>
                    <Nav/>
                </div>
            </div>
        )
    }
}

export default Aside;
