import React, {useEffect, useState} from "react";
import {Importer, ImporterField} from "react-csv-importer";
import {Col,Row} from "react-bootstrap";

// theme CSS for React CSV Importer
import "react-csv-importer/dist/index.css";
import {Api} from "../../../../helpers/Api";
import {v4 as uuidv4} from "uuid";

// sample importer usage snippet, play around with the settings and try it out!
// (open console output to see sample results)
let importedRows = [];
const CSV = () => {

    const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(null);
    const [events, setEvents] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [processing, setProcessing] = useState(false);

    const [confirmation, setConfirmation] = useState(false);
    const [result, setResult] = useState(null);

    useEffect(() => {
        Api.getTemplates().then(response => {
            setEvents(response);
            console.log(response);
        })
    }, []);

    const reset = () => {
        window.location.reload();
    };
    const proceed = async () => {
        setProcessing(true);
        let template = templates[selectedTemplateIndex];
        let r = { success: 0, failed: 0 };
        for (let i = 0; i < importedRows.length; i++) {

            const payload = { data: {}, template: { data: {} } };
            payload.templateData = template;
            payload.template = template.id;
            payload.uuid = uuidv4();
            payload.data = importedRows[i];
            try {
                await Api.updateCertificate(payload);
                r.success++;
            } catch (e) {
                r.failed++;
            }
        }
        setResult(r);
        setProcessing(false);
    };

    let hasName = templates[selectedTemplateIndex]?.labels?.find(label=>{
        return label.text.toLowerCase() === "name";
    });

    let hasEmail = templates[selectedTemplateIndex]?.labels?.find(label=>{
        return label.text.toLowerCase() === "email";
    });

    return <div className="right_container">
        <div className="dashboard">
            <div className="inner-content csv">
                <div className="select-events">
                    <Row>
                    <Col md={4}>
                        <label>Please select event</label>
                        <select className="form-control" onChange={e => {
                            let t = events.find( t=>{
                                return t.id == e.target.value});
                                setTemplates(t.data);
                            }                    
                        }>
                            <option value=""></option>

                            {events.map((t, index) => {
                                return <option value={t.id}>{t.name}</option>
                            })}
                        </select>
                    </Col>
                    {templates &&
                    <Col md={4}>
                        <label>Please select event</label>
                        <select className="form-control" value={selectedTemplateIndex} onChange={e => {
                            setSelectedTemplateIndex(e.target.value)
                        }
                        }>
                            <option value=""></option>

                            {templates.map((t, index) => {
                                return <option value={index}>{t.TemplateName}</option>
                            })}
                        </select>
                    </Col>}
                    </Row>
                </div>
                {confirmation && !processing && !result &&
                    <div className="confirm text-white">
                        <p>You are about to generate {importedRows.length} certificates.</p>
                        <div className='Form-buttons'>
                            <button className='cancel' type="button" onClick={reset}>Cancel</button>
                            <button className='submit' type="button" onClick={proceed}>Proceed</button>
                        </div>
                    </div>

                }
                {result &&
                    <div className="result text-white">
                        <p> Success: {result.success}</p>
                        <p> Failed: {result.failed}</p>
                    </div>
                }
                {processing &&
                    <div className="processing text-white">
                        Processing, please wait...
                    </div>
                }
                {!!selectedTemplateIndex && !confirmation &&
                    <Importer
                        chunkSize={10000} // optional, internal parsing chunk size in bytes
                        assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
                        restartable={false} // optional, lets user choose to upload another file when import is complete
                        onStart={({ file, fields }) => {
                            // optional, invoked when user has mapped columns and started import
                            console.log("starting import of file", file, "with fields", fields);
                        }}
                        processChunk={async (rows) => {
                            // required, receives a list of parsed objects based on defined fields and user column mapping;
                            // may be called several times if file is large
                            // (if this callback returns a promise, the widget will wait for it before parsing more data)
                            console.log("received batch of rows", rows);
                            importedRows = [...importedRows, ...rows];
                        }}
                        onComplete={({ file, fields }) => {
                            // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
                            setConfirmation(true);
                        }}
                        onClose={() => {
                            // optional, invoked when import is done and user clicked "Finish"
                            // (if this is not specified, the widget lets the user upload another file)
                        }}
                    >
                        {/* {!hasName &&
                        <ImporterField name={'name'} label="Name" />
                        }
                        {!hasEmail &&
                        <ImporterField name={'email'} label="Email" />
                        } */}

                        {templates[selectedTemplateIndex].label.map(label => {
                            return <ImporterField name={label.id} label={label.text} />
                        })}
                    </Importer>
                }
            </div></div></div>
}

export default CSV;
