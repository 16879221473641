import React, {useCallback, useEffect, useState} from 'react'
import Table from "../Components/Table/Table";
import {Link, useParams, useNavigate} from "react-router-dom";
import {Api} from "../../../helpers/Api";
import Heading from "../Components/Heading/Heading";
import columnConfig from "../Main/Dashboard/columns";
import {connect} from "react-redux";
import {Button} from "@mui/material";

const TemplatesList = ({user, ...props}) => {


    const params = {path: 'templates_2'};
    let config = columnConfig[params.path];
    const navigate = useNavigate();
    const [state, setState] = useState({
        loading: true,
        error: null,
        config: config,
        page: 1,
        limit: 200,
        total: 0,
        show: false,
    });

    const [posts, setPosts] = useState([]);
    const [data, setData] = useState({});
    const [total, setTotal] = useState(-1);


    const {id: templateId} = useParams();

    const loadData = useCallback(() => {
        setState(s => {
            return {...s, loading: true}
        });
        let start = 0;
        const limit = state.limit;
        const page = state.page;

        start = (page - 1) * limit;
        Api.call(`/${config.url}/count?template=${templateId}`).then(response => {
            setTotal(response);
        });

        Api.call(`/templates/${templateId}`).then(response => {
            console.log("dataa");
            console.log(response);
            let d = response;
            console.log(d);
            setData(d);
            setPosts(d.data)
        }).catch(console.log).finally(_ => {
            setState(s => {
                return {...s, loading: false}
            });
        });
    }, [config.url, state.page, state.limit]);

    const onPaginate = (page) => {
        setState({...state, page: page, loading: true})
    };

    useEffect(() => {
        if (!user) {
            return;
        }
        loadData()
    }, [loadData, params.path, user]);

    const parsePatient = (post) => {
        let row = state.config.columns.map((column) => {
            if (column.accessor) {
                return column.accessor(post);
            }
            return post[column.key];
        });
        return row;
    };

    const renderRow = (row, index) => {
        let columnIndex = 0;
        let patient = parsePatient(row);
        return <tr key={index}>
            {patient.map(column => {
                return renderColumn(column, columnIndex++, index, patient, row);
            })}</tr>
    };

    const selectAll = () => {
        let allSelected = !state.allSelected;
        let row = state.row.map(patient => {
            patient.selected = allSelected;
            return patient;
        });
        setState({...state, row: row, allSelected: allSelected});
    };

    const onDelete = (event, index) => {
        event.preventDefault();
        if (!window.confirm('Are you sure you want to delete this item?')) {
            return;
        }
        posts.splice(index,1);
        data.data = posts;
        Api.call(`/${config.url}/${templateId}`, "PUT",data).then(response => {
            loadData();
        })
    };

    const onUp = (event, index) => {
        event.preventDefault();
        setState({...state, loading: true})
        let post = posts[index];
        Api.call(`/${config.url}/${post.id}/move/up`, "POST").then(response => {
        }).catch(() => {
        }).finally(loadData)
    };

    const onDown = (event, index) => {
        setState({...state, loading: true})
        event.preventDefault();
        let post = posts[index];
        Api.call(`/${config.url}/${post.id}/move/down`, "POST").then(response => {
        }).catch(() => {
        }).finally(loadData)
    };

    const renderColumn = (column, index, rowIndex, row, originalRow) => {
        if (index === row.length - 1) {

            if (state.config.renderActions) {
                return state.config.renderActions(column, index, rowIndex, row, originalRow, onUp , onDown,onDelete, state, config);
            } else {
                return <td key={index}>
                    <ul>
                        <li>
                            <Link to={`/templates_2/${templateId}/${rowIndex}`}>
                                <img width="16px" src="../../../images/edit-icon.png" alt="edit"/>
                            </Link>
                        </li>
                        <li>
                            <Link to="/" onClick={e => onDelete(e, rowIndex)}>
                                <img width="16px" src="../../../images/garbage.png" alt="edit"/>
                            </Link>
                        </li>
                    </ul>
                </td>
            }

        }
        if (typeof column === "boolean") {
            return <td key={index}><span style={{color: column ? "green" : "red"}}>{column ? "Yes" : "No"}</span></td>
        }
        console.log("Rendering ", column)
        return <td key={index}>{column}</td>
    };
    const renderHeaderColumn = (column, index) => {
        return (
            <th key={index}>
                {column.name}
            </th>
        );
    };
    if(state.loading === false && posts.length == 0)
    {
        return (
            <div className="right_container">
                <div className="dashboard">
                    <div className='inner-content'>
                        {/* <Heading name={'Certificates'} refreshlink="#" link={`/certificates/new`}
                                 linkName="Add New" url={"certificates"}/> */}
                        <div className="heading-for-certificate">
                            <h2>{`Templates Of ${data.name}`}</h2>
                            <p>
                                There are no Templates added yet.
                                Start adding Templates by clicking on add new school.
                             </p>
                            <ul>
                                <li>
                                    <Link to={`/templates_2/${templateId}/new`}>
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg>
                                        Add New
                                    </Link>
                                </li> 
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    else
    {
    return (
        <div className="right_container">
            <div className="dashboard">
                <div className='inner-content'>
                    <Heading name={`Templates Of ${data.name}`} onRefresh={loadData} refreshlink="#" 
                            link={`/templates_2/${templateId}/new`} linkName="Add New" url={params.path}/>
                    <div className='commen-table'>
                        <Table renderColumn={renderColumn} loading={state.loading}
                            renderRow={renderRow} renderHeaderColumn={renderHeaderColumn}
                            columns={state.config.columns} cardFooter="cardFooter" total={total}
                            currentPage={state.page} perPage={state.limit}
                            rows={posts} selected="Invite Selected" onPaginate={onPaginate}
                            onChangePerPage={(limit) => {
                            setState({...state, limit});
                            }}
                        />
                    </div>
                    <div className='Form-buttons'>
                        <Button className="mt-2 add-label" onClick={()=>{
                                    navigate(`/templates`)}}>Go Back</Button>
                    </div>
                </div>
            </div>
        </div>
    )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        dispatchLogin: (user) => dispatch({type: 'LOGIN', data: user})
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplatesList)
